import React from 'react';
import { useTranslation } from 'react-i18next';

import TextareaInputField from '@common/components/form/inputs/textarea';
import ExchangeInfo from '../exchange/info';
import SelectReceivers from '../select-receivers';
import SelectFunctionOrNetwork from '../select-function-network';

import { useAppSelector } from '@common/hooks';
import { selected as selectedUser } from '@modules/core/selectors/logged-user';

import { Shift } from '@modules/schedules/types/objects';
import { User } from '@common/types/objects';

type SelectSendListProps = {
  type: string;
  functionIds: string[];
  flexChangeIntegrated: boolean;
  shift: Partial<Shift>;
  receivers: User[] | null;
  setReceivers: (receivers: User[] | null) => void;
};

const SelectSendList = ({
  flexChangeIntegrated, shift, receivers, setReceivers, type, functionIds,
}: SelectSendListProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(selectedUser);

  return (
    <div className="Form__block">
      <ExchangeInfo
        exchange={{
          ...shift,
          user: loggedUser,
        }}
      />
      <TextareaInputField
        name="description"
        className="Form__control Form__block__container"
        placeholder={t('flexchange:add_exchange_form_integrated_description_placeholder')}
      />
      {flexChangeIntegrated && shift?.id && (
        <SelectReceivers
          shiftId={shift.id}
          receivers={receivers}
          setReceivers={setReceivers}
        />
      )}
      {(!flexChangeIntegrated || !shift?.id) && (
        <SelectFunctionOrNetwork
          type={type}
          functionIds={functionIds}
          disabled={!!shift.function?.id}
        />
      )}
    </div>
  );
};

export default SelectSendList;
