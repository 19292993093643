import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import CheckboxValues from '@common/components/form/inputs/checkbox-values';
import CheckboxGroup from '@common/components/form/inputs/checkbox-group';
import { Label } from '@common/components/form';
import PureList from '@common/components/list';

import mergeNames from '@common/utils/merged-names-text';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/network/selectors/network';
import * as organisationSelector from '../../../organisation/selectors/organisation';
import { EExchangeTypes } from '../../definitions';

type SendListOwnProps = {
  type: string;
  functionIds: string[];
  disabled?: boolean;
};

const SelectFunctionOrNetwork = ({
  type, functionIds, disabled = false,
}: SendListOwnProps) => {
  const { t } = useTranslation();
  const network = useAppSelector(selected);
  const functions = useAppSelector((state) => organisationSelector.functions(state));
  const selectedFunctions = functions.filter(({ id }) => functionIds.includes(id));

  const label = useMemo(() => {
    let text = t('flexchange:send_list_select_receivers');
    if (type === EExchangeTypes.ALL) {
      text = t('flexchange:send_list_send_to_everyone_in', {
        networkName: network.name,
      });
    } else if (selectedFunctions.length > 0) {
      text = t('flexchange:send_list_send_to', {
        names: mergeNames(selectedFunctions, 2, 'name', (name) => `${name}`).join(''),
      });
    }
    return (
      <Label forInput="type" text={text} />
    );
  }, [selectedFunctions, type, network.name, t]);


  return (
    <div className="Form__block">
      {label}
      <div className="Form__block__container">
        <CheckboxValues
          name="type"
          label={t('flexchange:send_list_all_network')}
          values={[EExchangeTypes.FUNCTION, EExchangeTypes.ALL]}
          disabled={disabled}
        />
        <PureList
          items={functions}
          renderRow={CheckboxGroup}
          rowProps={(item) => ({
            id: item.id,
            name: 'function_ids',
            disabled: type === EExchangeTypes.ALL || disabled,
            label: item.name,
          })}
        />
      </div>
    </div>
  );
};

export default SelectFunctionOrNetwork;
