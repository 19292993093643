import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { omit, pick } from 'lodash';
import moment, { Moment } from 'moment';

import Modal from '@common/components/modal';
import Carousel from '@common/components/carousel';
import * as Alert from '@common/services/alert';
import ShiftDetail from '@modules/schedules/components/shift-detail';
import SelectShiftIntegrated from '@modules/flexchange/components/select-shift-integrated';
import SelectShiftManual from '@modules/flexchange/components/select-shift-manual';
import SelectSendList from '@modules/flexchange/components/select-send-list';

import { useAppSelector, useFormValues } from '@common/hooks';
import { selected } from '@modules/organisation/selectors/organisation';
import postExchange from '../../actions/post-exchange';

import { convertTimeToMoment } from '@modules/flexchange/utils/exchange';
import { combineClassNames } from '@common/utils/combineClassNames';

import { Shift } from '@modules/schedules/types/objects';
import { EExchangeTypes } from '../../definitions';
import { User } from '@common/types/objects';

type CreateExchangeFormOwnProps = {
  shift?: Shift;
  date?: Moment;
  show: boolean;
  schedulesIntegrated: boolean;
  onClose: () => void;
};

type FormData = {
  date: Moment;
  start_time: string;
  end_time: string;
  no_end_time: boolean;
  description: string;
  function_ids: string[];
  type: string;
};

const CreateExchangeForm = ({
  shift, date: preselectedDate, schedulesIntegrated, submitting, form, show,
  onClose, change, initialize, handleSubmit,
}: InjectedFormProps<FormData, CreateExchangeFormOwnProps, boolean> & CreateExchangeFormOwnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organisation = useAppSelector(selected);
  const [receivers, setReveivers] = useState<User[] | null>(null);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  const [showShiftDetail, setShowShiftDetail] = useState(false);
  const [isSelectingSendList, setIsSelectingSendList] = useState(false);

  const flexChangeIntegrated = organisation.integration?.flexchange_type === 'integrated';
  const isSelectingShift = schedulesIntegrated && !selectedShift && !shift;
  const currentShift = selectedShift || shift;
  const shiftId = currentShift?.id;

  const {
    date, start_time, no_end_time, end_time, function_ids, type,
  } = useFormValues<FormData>(form);

  const className = combineClassNames('AddExchangeForm', {
    'modal--color-grey': !showShiftDetail,
    'modal-datetimepicker': !schedulesIntegrated && !isSelectingSendList,
  });

  useEffect(() => change('date', preselectedDate), [preselectedDate]);

  useEffect(() => {
    if (!show) {
      initialize(initialValues);
      setSelectedShift(null);
      setIsSelectingSendList(false);
    }
  }, [show, setSelectedShift, setIsSelectingSendList]);

  useEffect(() => {
    if (shift) {
      setShowShiftDetail(true);
    }
  }, [shift, setShowShiftDetail]);

  useEffect(() => {
    if (!selectedShift || !isSelectingSendList) {
      change('function_ids', []);
      change('type', EExchangeTypes.FUNCTION);
    }
  }, [selectedShift, isSelectingSendList]);

  useEffect(() => {
    if (currentShift?.function?.id) {
      change('function_ids', [currentShift?.function?.id]);
    }
  }, [currentShift?.function?.id]);

  const onSubmit = async (values: FormData) => {
    let payload: any = { ...values };
    if (values.no_end_time) payload.end_time = null;

    if (schedulesIntegrated && (shift || selectedShift)) {
      payload = {
        ...payload,
        ...pick(shift || selectedShift, ['date', 'start_time', 'end_time']),
        shift_id: shiftId ? parseInt(shiftId, 10) : null,
      };
    }

    if (flexChangeIntegrated && shiftId) {
      payload = {
        ...omit(payload, ['function_ids']),
        type: EExchangeTypes.USER,
        user_ids: receivers?.map((user) => user.id) || [],
      };
    }

    try {
      await dispatch(postExchange(payload));
      Alert.success(
        <Trans
          i18nKey="flexchange:add_exchange_form_integrated_alert_success"
          components={[<b>a</b>]}
          values={{
            date: moment(shift?.date || selectedShift?.date || values.date).format('dddd D MMMM'),
          }}
        />,
      );
      onClose();
    } catch (response: any) {
      return Alert.forStatus(response.status_code, {
        warning: t('flexchange:add_exchange_form_warning_creating_exchange'),
        error: t('flexchange:add_exchange_form_error_creating_exchange'),
      });
    }
  };

  const footerProps: any = {
    submitting,
    submitText: t('flexchange:add_exchange_form_default_send'),
    maxIndex: 1,
  };
  if (isSelectingSendList || currentShift) {
    footerProps.invalid = flexChangeIntegrated && shiftId ?
      receivers === null :
      type === EExchangeTypes.FUNCTION && function_ids.length === 0;
  }
  if (!shift && schedulesIntegrated) {
    footerProps.index = selectedShift ? 1 : 0;
    footerProps.goPrevious = () => setSelectedShift(null);
  }
  if (shift) {
    footerProps.index = !showShiftDetail ? 1 : 0;
    footerProps.goPrevious = () => setShowShiftDetail(true);
  }

  return (
    <Modal
      list
      show={show}
      onClose={onClose}
      size="small"
      className={className}
      title={showShiftDetail ?
        t('schedules:container_modal_posting_title') :
        t('flexchange:container_create_shift')}
      content={(
        <Carousel
          indicator={false}
          onIndexWasUpdated={(newIndex: number) => {
            if (newIndex === 1) setIsSelectingSendList(true);
            else if (newIndex === 0) setIsSelectingSendList(false);
          }}
          footer={{
            component: showShiftDetail || isSelectingShift ? null : undefined,
            onCancel: onClose,
            onSubmit: handleSubmit(onSubmit),
            props: footerProps,
          }}
        >
          {(shift && showShiftDetail && (
            <Carousel.Card>
              <ShiftDetail
                shift={shift}
                createExchangeFromShift={() => setShowShiftDetail(false)}
              />
            </Carousel.Card>
          )) || null}
          {(!schedulesIntegrated && (
            <Carousel.Card>
              <SelectShiftManual
                startTime={start_time}
                endTime={end_time}
                noEndTime={no_end_time}
                date={date}
                change={change}
              />
            </Carousel.Card>
          )) || null}
          {(isSelectingShift && (
            <Carousel.Card>
              <SelectShiftIntegrated setShift={setSelectedShift} />
            </Carousel.Card>
          )) || null}
          <Carousel.Card>
            <SelectSendList
              type={type}
              functionIds={function_ids}
              receivers={receivers}
              setReceivers={setReveivers}
              flexChangeIntegrated={flexChangeIntegrated}
              shift={currentShift || {
                date: date.toISOString(),
                start_time: start_time ? convertTimeToMoment(start_time) : '',
                end_time: end_time && !no_end_time ? convertTimeToMoment(end_time) : '',
              }}
            />
          </Carousel.Card>
        </Carousel>
      )}
    />
  );
};

const initialValues = {
  description: '',
  type: EExchangeTypes.FUNCTION,
  function_ids: [],
  date: moment(),
  start_time: '10:00',
  end_time: '15:00',
  no_end_time: false,
};

export default reduxForm<FormData, CreateExchangeFormOwnProps, boolean>({
  form: 'add-exchange',
  initialValues,
})(CreateExchangeForm);
