import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Label from '@common/components/form/label';
import ProfileImage from '@common/components/profile-image';
import Spinner from '@common/components/spinner';
import { PureList } from '@common/components/list';

import fetchReceivers from '../../actions/fetch-receivers';

import { User } from '@common/types/objects';

type ReceiversListProps = {
  shiftId: string;
  receivers: User[] | null;
  setReceivers: (receivers: User[] | null) => void;
};

const SelectReceivers = ({ shiftId, receivers, setReceivers }: ReceiversListProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const load = async () => {
      const { items } = await dispatch(fetchReceivers(shiftId)) as unknown as { items: User[] };
      setReceivers(items);
    };
    if (shiftId) load();
    return () => setReceivers([]);
  }, [shiftId, dispatch, setReceivers]);
  return (
    <div className="Form__block">
      <Label
        text={t('flexchange:add_exchange_form_integrated_send_to', {
          count: receivers?.length || 0,
        })}
      />
      <div className="Form__block__container Shift__Receivers">
        {Array.isArray(receivers) ? (
          <PureList
            items={receivers}
            renderRow={ProfileImage}
            rowProps={(item) => ({
              user: item,
              size: 30,
            })}
            placeholder={(
              <small>
                {t('flexchange:add_exchange_form_integrated_receivers_placeholder')}
              </small>
            )}
          />
        ) : (
          <Spinner size="small" centered />
        )}
      </div>
    </div>
  );
};

export default SelectReceivers;
