import React, { useMemo } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import Profile from '@common/components/profile/large';
import Text from '@common/components/text';
import mergeNames from '@common/utils/merged-names-text';

import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/core/selectors/logged-user';
import { Exchange } from '@modules/flexchange/types/objects';
import { User } from '@common/types/objects';

type ExchangeInfoOwnProps = {
  exchange: Partial<Exchange>;
  user?: User;
};

const ExchangeInfo = ({ exchange }: ExchangeInfoOwnProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(selected);

  const title = useMemo(() => {
    if (exchange.title) return exchange.title;
    return [
      moment(exchange.start_time).format('HH:mm'),
      '-',
      exchange.end_time ? moment(exchange.end_time).format('HH:mm') : t('flexchange:exchange_info_no_end_time'),
    ].join(' ');
  }, [t, exchange]);

  const createdIn = !!exchange.created_in && exchange.created_for !== 'USER' && (
    <small className="Exchange__Info__placed-in">
      <Trans
        i18nKey="flexchange:exchange_info_send_to"
        values={{
          names: mergeNames(exchange.created_in.values, 3, null, (name) => `${name}`).join(''),
        }}
      />
    </small>
  );

  return (
    <div className="Exchange__Info">
      <div className="Exchange__Info__date">
        {moment(exchange.date).format('dddd D MMMM')}
      </div>
      <Profile
        inverted
        size={45}
        user={exchange.user}
        className="Exchange__User"
        enablePopover={exchange.user && loggedUser && exchange.user.id !== loggedUser.id}
      >
        <span className="Exchange__title">
          {title}
        </span>
      </Profile>
      {typeof exchange.description === 'string' && (
        <div className="Exchange__Info__description">
          <Text>{exchange.description}</Text>
        </div>
      )}
      {createdIn}
    </div>
  );
};

export default ExchangeInfo;
