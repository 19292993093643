import React, { useEffect } from 'react';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

import { CheckboxInput, DatePickerInput, Group, Row } from '@common/components/form';
import Label from '@common/components/form/label';
import TimePickerInput from '@common/components/form/inputs/time';

type ExchangeDateProps = {
  startTime: string;
  endTime: string;
  noEndTime: boolean;
  date: Moment;
  change: (field: string, value: any) => void;
};

const SelectShiftManual = ({
  startTime, endTime, noEndTime, date, change,
}: ExchangeDateProps) => {
  const { t } = useTranslation();
  const dateText = date ? date.format('dddd DD MMMM') : null;

  useEffect(() => {
    if (startTime > endTime) {
      change('end_time', startTime);
    }
  }, [startTime, endTime]);

  return (
    <>
      <DatePickerInput
        inline
        name="date"
        minDate={moment().subtract(5, 'days')}
        calendarClassName="inline"
      />
      <div className="Form__block">
        <div className="Form__block__container Form__block__container--padding-large">
          <div className="AddExchangeForm__date">{dateText}</div>
          <Row>
            <Group>
              <Label forInput="start_time" text={t('flexchange:add_exchange_form_default_start_time')} />
              <TimePickerInput
                minutesPerStep={5}
                name="start_time"
              />
            </Group>
            <Group>
              <Label
                forInput="end_time"
                text={t('flexchange:add_exchange_form_default_end_time')}
              />
              <TimePickerInput
                minutesPerStep={5}
                disabled={noEndTime}
                name="end_time"
                minValue={startTime}
              />
              <div style={{ marginTop: 8 }}>
                <CheckboxInput
                  name="no_end_time"
                  label={t('flexchange:add_exchange_form_default_disable_end_time')}
                />
              </div>
            </Group>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SelectShiftManual;
