import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';

import { AsyncList } from '@common/components/list';
import ShiftItem from './shift';

import { Shift } from '@modules/schedules/types/objects';
import * as fetchShifts from '../../../schedules/actions/fetch-shifts';

type AddExchangeShiftsListOwnProps = {
  setShift: (shift: Shift) => void;
};

const SelectShiftIntegrated = ({ setShift }: AddExchangeShiftsListOwnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <AsyncList
      containerClassName="Shifts"
      data={{
        useCursor: undefined,
        onFetch: async () => {
          const { data } = await dispatch(fetchShifts.call()) as unknown as { data: Shift[] };
          return ({
            data: data
              .map((item: Shift) => R.assoc('id', item.id || item.start_time, item))
              .filter((item: Shift) => item.exchange_id === null),
            meta: {
              pagination: {},
            },
          });
        }
      }}
      renderRow={ShiftItem}
      rowProps={{
        onClick: (selectedShift: Shift) => setShift(selectedShift),
      }}
      placeholder={(
        <small>
          {t('flexchange:add_exchange_form_no_shifts_found')}
        </small>
      )}
    />
  );
};

export default SelectShiftIntegrated;
