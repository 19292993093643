import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import ShiftInfo from '../shift';
import { Button } from '@common/components/button';

import { Shift } from '@modules/schedules/types/objects';
import { useAppSelector } from '@common/hooks';
import { selected } from '@modules/network/selectors/network';

type ShiftDetailOwnProps = {
  shift: Shift;
  createExchangeFromShift: () => void;
};

const ShiftDetail = ({
  shift, createExchangeFromShift,
}: ShiftDetailOwnProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const network = useAppSelector(selected);

  const handleViewShift = () => {
    if (shift?.exchange_id) {
      history.push(`/networks/${network.id}/flexchange/exchanges/${shift.exchange_id}`);
    }
  };

  return (
    <>
      <ShiftInfo shift={shift} />
      <div className="Shift__Flexchange">
        {
          t(
            shift.exchange_id ?
              'schedules:container_modal_posted_view_description' :
              'schedules:container_modal_posting_description'
          )
        }
        <div>
          <Button
            type="inverted-primary"
            onClick={shift.exchange_id ? handleViewShift : createExchangeFromShift}
          >
            {
              t(
                shift.exchange_id ?
                  'schedules:container_modal_posted_view_button' :
                  'schedules:container_modal_posting_button'
              )
            }
          </Button>
        </div>
      </div>
    </>
  );
};

export default ShiftDetail;
