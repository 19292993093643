import * as React from 'react';
import * as R from 'ramda';
import Card from './card';
import Footer from './footer';

class Carousel extends React.Component {
  static defaultProps = {
    indicator: true,
    footer: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      index: 0,
    };
  }

  static props;
  static Card;
  static Footer;

  getProps(i) {
    const { index } = this.state;
    const { onIndexWasUpdated } = this.props;

    const children = R.reject(R.isNil, this.props.children);

    return {
      index: i,
      position: i - index,
      maxIndex: children.length - 1,
      goPrevious: index > 0 ? () => {
        this.setState({ index: index - 1 });
        if (onIndexWasUpdated) onIndexWasUpdated(index - 1);
      } : null,
      goNext: index < children.length - 1 ? () => {
        this.setState({ index: index + 1 });
        if (onIndexWasUpdated) onIndexWasUpdated(index + 1);
      } : null,
    };
  }

  getCards() {
    const { children } = this.props;

    return React.Children.map(R.reject(R.isNil, children), (child, i) => (
      React.cloneElement(child, this.getProps(i))
    ));
  }

  transformToIndicator(child) {
    let className = 'Carousel__footer__indicator';
    if (child.props.position === 0) className += ' Carousel__footer__indicator--state-active';

    return <div key={child.props.position} className={className} />;
  }

  createFooter(cards) {
    const { index } = this.state;
    const { indicator, footer: { component = Footer, onCancel, onSubmit, props } } = this.props;

    if (component) {
      return (
        <Footer
          {...{
            ...this.getProps(index),
            onCancel,
            onSubmit,
            ...props,
          }}
        />
      );
    }

    const indicators = cards.map(this.transformToIndicator);

    return (
      <div className="Carousel__footer">{indicator ? indicators : null}</div>
    );
  }

  render() {
    const cards = this.getCards();
    const visibleCards = cards.filter(({ props }) => props.position >= -1 && props.position <= 1);
    const footer = this.createFooter(cards);

    return (
      <div className="Carousel">
        <div className="Carousel__inner">
          {visibleCards}
        </div>

        {footer}
      </div>
    );
  }
}

Carousel.Card = Card;
Carousel.Footer = Footer;

export default Carousel;
